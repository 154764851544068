import Job from '../../Entities/Job';
import GetPayRangeString from '../../utils/getPayRangeString';
import './jobPostSmall.scss';
import { AllCurrencyIcon } from '@fluentui/react-icons-mdl2';
import { Skeleton } from '@mui/material';

import PromotedBackground from './promoted_background.inline.svg';

export type JobPostSmallParameters = {
  job?: Job,
  featured?: Boolean
}

const JobPostSmall = ({job, featured}: JobPostSmallParameters) => {
  let className="job-post-small";
  if (featured) {
    className += ' job-post-small--featured';
  }
  //AllCurrency, Money, Market, Running
  return (
    <div className={className}>
      <div className="job-post-small__logo">
        {!job || !job.logoImageUrl ? (
          <Skeleton variant="circular" width={100} height={100} animation="wave" />
        ) : (
          <img src={job.logoImageUrl} alt="company logo" className="job-post-small__logo-img" />
        )}        
      </div>
      <div className="job-post-small__details">
        <div className="job-post-small__pay-range">
          <span className="job-post-small__currency"><AllCurrencyIcon /></span>
          {!job || !job.payRangeStart || !job.payRangeEnd ? (
            <Skeleton animation="wave" width={175} />
          ) : (
            <>{GetPayRangeString(job.payRangeStart, job.payRangeEnd)}</>
          )}
        </div>
        <div className="job-post-small__company-title">
          <div className="job-post-small__company">
            {!job || !job.company ? (
              <Skeleton animation="wave" width={175} />
            ) : (
              <>{job.company}</>
            )}
          </div>
          <div className="job-post-small__title">
            {!job || !job.jobTitle ? (
              <Skeleton animation="wave" width={175} />
            ) : (
              <>{job.jobTitle}</>
            )}
          </div>
        </div>
        <div className="job-post-small__job-type">{job && job.jobType}</div>
        {job && job.promoted && (
        <div className="job-post-small__promoted">
          <div className="job-post-small__promoted-container">
            <PromotedBackground />
            <div className="job-post-small__promoted-text">promoted</div>
          </div>
        </div>
        )}
      </div>      
    </div>
  )
}

export default JobPostSmall;