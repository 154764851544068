export default function getPayRangeString(payRangeStart?: number, payRangeEnd?: number):string {
  if (!payRangeStart || !payRangeEnd) {
    return '';
  }
  let payRange = '';
  if (payRangeStart > 999) {
    payRange += payRangeStart + ' - ';
  } else {
    payRange += payRangeStart + 'K - ';
  }
  if (payRangeEnd > 999) {
    payRange += payRangeEnd;
  } else {
    payRange += payRangeEnd + 'K';
  }

  return payRange;
};