import MagentaDust from './magenta_dust_bg.jpg';
import PinkBokeh from './pink_bokeh_bg.jpg';
import AbstractBlue from './abstract_blue_bg.jpg';
import BlueClouds from './blue_clouds_bg.jpg';
import PurpleScratch from './abstract_purple_scratch.jpg';
import PinkSmoke from './pink_purple_smoke.jpg';
import BlueInk from './blue_inky_smoke.jpg';
import AbstractPink from '../abstract_logo_bg.jpg';

export enum TextBackground {
  MagentaDust = 1,
  PinkBokeh = 2,
  AbstractBlue = 3,
  BlueClouds = 4,
  PurpleScratch = 5,
  PinkSmoke = 6,
  BlueInk = 7,
  AbstractPink = 8
}

export type SvgTextProps = {
  children: React.ReactNode,
  className?: string,
  id: String,
  textBackground: TextBackground
}

const svgText = ({children, className, id, textBackground}: SvgTextProps) => {
  let imageSource = MagentaDust;
  if (textBackground === TextBackground.PinkBokeh) {
    imageSource = PinkBokeh;
  } else if (textBackground === TextBackground.AbstractBlue) {
    imageSource = AbstractBlue;
  } else if (textBackground === TextBackground.BlueClouds) {
    imageSource = BlueClouds;
  } else if (textBackground === TextBackground.PurpleScratch) {
    imageSource = PurpleScratch;
  } else if (textBackground === TextBackground.PinkSmoke) {
    imageSource = PinkSmoke;
  } else if (textBackground === TextBackground.BlueInk) {
    imageSource = BlueInk;
  } else if (textBackground === TextBackground.AbstractPink) {
    imageSource = AbstractPink;
  }
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" version="1.1" height="100%" width="100%">
      <defs>
        <mask id={`text-mask-${id}`} style={{fill: 'white'}} maskUnits="userSpaceOnUse" x="0" y="0" width="100%" height="100%">
          <text x="0" y="0" id="text">{children}</text>
        </mask>
      </defs>
      <image id="image" style={{ mask: `url(#text-mask-${id})`, width: "100%", height: "auto" }} xlinkHref={imageSource} x="0" y="0" width="100%" height="100%"/>
    </svg>
  )
};

export default svgText;
